





















































import { Inject } from '@cds/common';
import { Component, Vue, Prop, Emit, Watch } from 'vue-property-decorator';
import { ADD, EDIT, COPY, DETAIL, EDITOR_CONFIG } from '@/config/constant';
import MainType from './MainType.vue';
import { BasicInformationService } from '@/services/abilityAssessment/basic-information-service';
import { LevelInformationService } from '@/services/abilityAssessment/level-information-service';
import { deepCopy } from '@/services/utils';
import { isEmpty, map, filter } from 'lodash';

@Component({
    components: { MainType }
})
export default class ManageDialog extends Vue {
    @Prop(String) private dialogType!: string;
    @Prop() private defaultData: object | null;
    @Emit() cancel(isRefresh: boolean = false) {
        return isRefresh;
    }
    @Inject(BasicInformationService)
    private basicInformationService!: BasicInformationService;
    @Inject(LevelInformationService)
    private levelInformationService!: LevelInformationService;

    public ADD: string = ADD;
    public EDIT: string = EDIT;
    public COPY: string = COPY;
    public DETAIL: string = DETAIL;
    public EDITOR_CONFIG: any = EDITOR_CONFIG({});
    public formInfo: any = {
        priority: 1,
    };
    public rules: any = {
        title: [
            { required: true, message: '请输入测评标题', trigger: 'blur' },
            { max: 100, message: '长度不能超过 100 个字', trigger: ["change", "blur"] },
        ],
        priority: [
            { required: true, message: '请输入优先级个数', trigger: 'blur' }
        ],
        windowsRange: [
            { required: true, message: '请选择测评窗口期', trigger: ['blur', 'change'] }
        ],
        skillLevelId: [
            { required: true, message: '请选择测评等级类型', trigger: ['blur', 'change'] }
        ],
        description: [
            { max: 1000, message: "长度不能超过 1000 个字", trigger: ["change", "blur"] },
        ],
    };
    public labelPosition: String = 'right';
    public title: string = '';
    public tableData: any[] = [];
    public loading: Boolean = false;
    public levelOptions: any[] = [];
    public multipleSelectionKey: any[] = [];
    public visible: boolean = true;
    public levelList: any[] = [];
    public assessStatus: string = ""; // 测评单状态
    // 子组件相关
    public showChild: boolean = false;
    public childData: any[] = [];

    // 是否是已发布状态(编辑时)
    get isReleaseStatus() {
        return this.dialogType === EDIT && this.assessStatus === "RELEASE";
    }

    public created(): void {
        console.log("dialogType", this.dialogType);
        this.initData();
    }
    // 清空数据
    public clearData() {
        this.childData = [];
    }
    // 获取测评评级名称列表
    public async getLevelList() {
        const params = {
            size: 9999,
            current: 1,
        }
        const res = await this.levelInformationService.getLevelList(params);
        if (res) {
            if (res.records && res.records.length) {
                this.levelOptions = res.records.map(t => ({
                    label: t.title,
                    value: t.id
                }))
            }
        }
    }
    // 获取测评基本信息
    public async getBaseData() {
        const { id, status } = this.defaultData as any;
        const res = await this.basicInformationService.getDetailById(id);
        if (res) {
            // 获取测评等级项列表
            this.getLevelItem(res.skillLevelId);
            // 初始化表单
            switch (this.dialogType) {
                case EDIT:
                    this.assessStatus = status;
                    this.formInfo = { ...deepCopy(res), windowsRange: [res.effectAt, res.expireAt] };
                    break
                case COPY:
                    this.formInfo = { ...deepCopy(res), title: res.title + "-复制", windowsRange: [res.effectAt, res.expireAt] };
                    break
            }
            this.childData = deepCopy(res.primaryAssessmentTypes);
        }
    }
    // 初始化数据
    public async initData() {
        await this.getLevelList();
        switch (this.dialogType) {
            case ADD:
                this.title = '新增测评基本信息';
                this.showChild = true;
                break;
            case EDIT:
                this.title = '编辑测评基本信息';
                await this.getBaseData();
                this.showChild = true;
                break;
            case COPY:
                this.title = '复制测评基本信息';
                await this.getBaseData();
                this.showChild = true;
                break;
        }
    }
    // 拦截select点击事件
    public async handleSelectClick(val: string | number) {
        let prevVal = this.formInfo.skillLevelId;
        // 首次选择时，直接赋值不做提示
        if (!prevVal) {
            this.changeLevel(val);
            (this.$refs.selectLevel as any).blur();
        } else {
            // 重复点击同一选项时，不处理点击事件
            if (prevVal == val) {
                (this.$refs.selectLevel as any).blur();
                return
            };
            // 变更选项时，提示是否确认变更
            try {
                await this.$confirm('当前信息将会丢失，请确认是否要切换测评等级类型?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                });
                this.changeLevel(val);
            } catch (e) { }
        }
    }
    // 获取测评等级项列表
    public async getLevelItem(id: number | string) {
        // 根据等级名称获取对应等级项
        let res = await this.levelInformationService.getDetailById(id);
        if (res) {
            if (res.levelItems && res.levelItems.length) {
                this.levelList = res.levelItems;
            }
        }
        console.log("levelList", this.levelList);
    }
    // 改变测评等级类型
    public changeLevel(val: number | string) {
        // 赋值测评等级类型值
        this.$set(this.formInfo, "skillLevelId", val);
        // 获取测评等级项列表
        this.getLevelItem(val);
        // 清除所有子类型中的测评项数据
        (this.$refs.mainType as any).clearItems();
    }
    // 关闭自己
    public async handleClose() {
        try {
            await this.$confirm('确定要取消吗?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            });
            this.cancel();
        } catch (e) { }
    }
    public getFormPromise(form) {
        return new Promise(resolve => {
            form.validate(res => {
                resolve(res);
            });
        })
    }
    // 保存表单
    public async submitForm(): Promise<void> {
        const basicForm = this.$refs.formInfo;
        const mainTypeForm = (this.$refs.mainType as any).$refs.mainTypeFormInfo;
        const mainTypeDataValidate = new Promise((resolve, reject) => {
            let flag = (this.$refs.mainType as any).validateData();
            resolve(flag);
        })
        let checkArr = await Promise.all([...[basicForm, mainTypeForm].map(this.getFormPromise), mainTypeDataValidate]);
        const validateResult = checkArr.every(item => !!item);
        if (!validateResult) {
            console.log("表单校验未通过，请重新检查提交内容");
            return;
        }
        // 表单校验通过
        // 处理主类型信息
        let primaryAssessmentTypes = deepCopy((this.$refs.mainType as any).formInfo.primaryAssessmentTypes);
        primaryAssessmentTypes = primaryAssessmentTypes.map(t => {
            delete t.mainTypeOptions;
            t.subAssessmentTypes.forEach(j => {
                delete j.multipleSelectionKey;
            })
            return t;
        })
        const params = {
            ...this.formInfo, // 测评基本信息
            effectAt: this.formInfo.windowsRange[0],
            expireAt: this.formInfo.windowsRange[1],
            primaryAssessmentTypes // 主类型信息
        }
        if (this.dialogType === ADD || this.dialogType === COPY) {
            // 复制时去掉主键id，其余id后端处理
            delete params.id;
            const res = await this.basicInformationService.createAssessment(params);
            if (res) {
                this.$message({
                    message: '添加成功',
                    type: 'success'
                });
                this.cancel(true);
            }
        } else if (this.dialogType === EDIT) {
            const res = await this.basicInformationService.updateAssessment(params);
            if (res) {
                this.$message({
                    message: '修改成功',
                    type: 'success'
                });
                this.cancel(true);
            }
        }
        console.log("表单校验通过", params);
    }
}
