






































































































































import { Inject } from '@cds/common';
import { Component, Vue, Prop, Emit, Watch } from 'vue-property-decorator';
import { ADD, EDIT, COPY, DETAIL } from '@/config/constant';
import ItemDialog from './ItemDialog.vue';
import { TypeManagementService } from '@/services/abilityAssessment/type-management-service';
import { deepCopy, keyValueConversion, addRandomKey, extractPlainText, reorderTable } from '@/services/utils';
import { isEmpty, map, filter } from 'lodash';

@Component({
    components: { ItemDialog },
    filters: {
        resolveRichText: (richText: string | null) => richText ? extractPlainText(richText) : ""
    }
})
export default class MainType extends Vue {
    @Prop(String) private dialogType!: string;
    @Prop() private defaultData!: any[];
    @Prop() private levelList!: any[];
    @Prop() private assessStatus: string | undefined;
    @Inject(TypeManagementService)
    private typeManagementService!: TypeManagementService;

    public ADD: string = ADD;
    public EDIT: string = EDIT;
    public COPY: string = COPY;
    public DETAIL: string = DETAIL;
    public formInfo: any = {
        primaryAssessmentTypes: []
    };
    // 数据结构
    // mainTypeOptions: [], // 记录主类型的下拉选
    // skillTypeId: "",
    // skillTypeTitle: "",
    // skillTypeDescription: "",
    // displayOrder: 1,
    // isShow: true,
    // subAssessmentTypes: [ // 子数据
    //     {
    //         skillTypeId: "",
    //         skillTypeTitle: "",
    //         skillTypeDescription: "",
    //         displayOrder: 1,
    //         isShow: true,
    //         assessmentTypeItems: [ // 测评项
    //             {
    //                 title: "",
    //                 description: "",
    //                 displayOrder: 1,
    //                 typeItemLevels: [
    //                     {
    //                         skillLevelId: 1,
    //                         description: "",
    //                     }
    //                 ]
    //             }
    //         ],
    //         multipleSelectionKey: [] // 记录已选的测评项的key
    //     }
    // ]
    public rules: any = {
        'skillTypeId': [
            { required: true, message: '测评主类型不能为空', trigger: 'change' },
        ],
    };
    public labelPosition: string = 'right';
    public title: string = '';
    public mainTypeList: any[] = [];
    public mainTypeOptions: any[] = [];
    // 存储已选的主类型id
    public selectedMainTypeIds: Array<string | number> = [];
    public tableData: any[] = [];
    public loading: boolean = false;
    public visible: boolean = true;
    // 子Dialog相关
    public itemDialogVisible: boolean = false;
    public itemDialogType: string = '';
    public currentMainIndex: number = -1;
    public currentSubIndex: number = -1;
    public currentRowIndex: number = -1;
    public currentRowData: any = null;

    // 是否是已发布状态(编辑时)
    get isReleaseStatus() {
        return this.dialogType === EDIT && this.assessStatus === "RELEASE";
    }
    // 是否禁用新增测评项btn
    get disabledAddItem() {
        return !this.levelList.length;
    }
    // 是否禁用新增主类型btn
    get disabledAddMainType() {
        // 只要有主类型未选，就不允许再点新增
        return this.formInfo.primaryAssessmentTypes.some(t => !t.skillTypeId);
    }
    public created(): void {
        this.initData();
    }
    // 获取测评主类型列表
    public async getMainTypeList() {
        const params = {
            size: 9999,
            current: 1,
        }
        const res = await this.typeManagementService.getTypeList(params);
        if (res) {
            if (res.records && res.records.length) {
                this.mainTypeList = res.records;
                this.mainTypeOptions = res.records.map(t => ({
                    label: t.title,
                    value: t.id,
                    description: t.description
                }))
            }
        }
        console.log("获取测评主类型列表", this.mainTypeOptions, this.mainTypeList);
    }
    // 获取测评子类型列表（根据主类型id）
    public async getSubTypeList(mainId: string | number, mainIdx: number) {
        let subArr = deepCopy(this.mainTypeList.find(t => t.id == mainId).subTypes);
        subArr = subArr.map(t => ({
            skillTypeId: t.id,
            skillTypeTitle: t.title,
            skillTypeDescription: t.description,
            displayOrder: 1,
            isShow: true,
            assessmentTypeItems: [],
            multipleSelectionKey: []
        }))
        // 赋值子类型数据
        this.$set(this.formInfo.primaryAssessmentTypes[mainIdx], "subAssessmentTypes", subArr);
    }
    // 初始化数据
    public async initData() {
        // 获取主类型列表
        await this.getMainTypeList();
        if (this.dialogType === ADD) {
            this.handleAddMainType();
            return;
        }
        if (this.dialogType === EDIT || this.dialogType === COPY) {
            // 存储已选的主类型id
            this.selectedMainTypeIds = this.defaultData.map(t => t.skillTypeId);
            // 初始化表单
            let _primaryAssessmentTypes = deepCopy(this.defaultData);
            _primaryAssessmentTypes = _primaryAssessmentTypes.map(t => {
                // 将已选的主类型在兄弟主类型的options选项中设为禁用
                t["mainTypeOptions"] = deepCopy(this.mainTypeOptions.map(j => {
                    j["disabled"] = false;
                    if (this.selectedMainTypeIds.includes(j.value) && j.value != t.skillTypeId) {
                        j["disabled"] = true;
                    }
                    return j;
                }));
                t.subAssessmentTypes = t.subAssessmentTypes.map(j => {
                    j["multipleSelectionKey"] = [];
                    if (j.assessmentTypeItems && j.assessmentTypeItems.length) {
                        j.assessmentTypeItems = addRandomKey(j.assessmentTypeItems);
                        j.assessmentTypeItems = j.assessmentTypeItems.map(k => {
                            if (!k.typeItemLevels) {
                                k["typeItemLevels"] = [];
                            }
                            return k;
                        });
                    } else {
                        j["assessmentTypeItems"] = [];
                    }
                    return j;
                });
                return t;
            });
            this.$set(this.formInfo, "primaryAssessmentTypes", _primaryAssessmentTypes);
            console.log("初始化表单 formInfo", this.formInfo);
            return;
        }
    }
    // 清空数据
    public clearData() {
        this.$set(this.formInfo.primaryAssessmentTypes[this.currentMainIndex].subAssessmentTypes[this.currentSubIndex], "multipleSelectionKey", []);
        (this.$refs[`multipleTable_${this.currentMainIndex}_${this.currentSubIndex}`][0] as any).clearSelection();
        this.itemDialogType = "";
        this.currentMainIndex = -1;
        this.currentSubIndex = -1;
        this.currentRowIndex = -1;
        this.currentRowData = null;
    }
    // 清除所有测评项数据
    public clearItems() {
        let primaryAssessmentTypes = deepCopy(this.formInfo.primaryAssessmentTypes);
        primaryAssessmentTypes = primaryAssessmentTypes.map(t => {
            if (t.subAssessmentTypes && t.subAssessmentTypes.length) {
                t.subAssessmentTypes.map(j => {
                    j["assessmentTypeItems"] = [];
                    return j;
                })
            }
            return t;
        })
        this.$set(this.formInfo, "primaryAssessmentTypes", primaryAssessmentTypes);
    }
    // 过滤主类型选项
    public filterMainTypeOptions() {
        // 禁用已选的主类型选项
        return this.mainTypeOptions.map((t) => {
            t["disabled"] = false;
            if (this.selectedMainTypeIds.includes(t.value)) {
                t["disabled"] = true;
            }
            return t;
        })
    }
    // 新增主类型
    public handleAddMainType() {
        let obj = {
            mainTypeOptions: this.filterMainTypeOptions(),
            skillTypeId: null,
            skillTypeTitle: "",
            skillTypeDescription: "",
            displayOrder: 1,
            isShow: true
        };
        // 向primaryAssessmentTypes中添加数据
        this.formInfo.primaryAssessmentTypes.push(deepCopy(obj))
        console.log("新增主类型 formInfo", this.formInfo);
    }
    // 拦截select点击事件
    public async handleSelectClick(row: any, mainIdx: number) {
        let prevVal = this.formInfo.primaryAssessmentTypes[mainIdx].skillTypeId;
        // 当前选项为禁用项时，不处理点击事件
        if (row.disabled) {
            this.$refs[`selectMainType_${mainIdx}`][0].blur();
            return
        }
        // 首次选择时，直接赋值不做提示
        if (!prevVal) {
            this.changeMainType(row.value, mainIdx);
            this.$refs[`selectMainType_${mainIdx}`][0].blur();
        } else {
            // 重复点击同一选项时，不处理点击事件
            if (prevVal == row.value) {
                this.$refs[`selectMainType_${mainIdx}`][0].blur();
                return
            };
            // 变更选项时，提示是否确认变更
            try {
                await this.$confirm('当前信息将会丢失，请确认是否要切换当前主类型?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                });
                this.changeMainType(row.value, mainIdx);
            } catch (e) { }
        }
    }
    // 执行变更主类型
    public async changeMainType(val: number | string, mainIdx: number) {
        // 赋值主类型值
        this.$set(this.formInfo.primaryAssessmentTypes[mainIdx], "skillTypeId", val);
        // 获取并赋值主类型名称
        let skillTypeTitle = keyValueConversion(val, this.formInfo.primaryAssessmentTypes[mainIdx].mainTypeOptions);
        this.$set(this.formInfo.primaryAssessmentTypes[mainIdx], "skillTypeTitle", skillTypeTitle);
        // 获取并赋值主类型说明
        let skillTypeDescription = keyValueConversion(val, this.formInfo.primaryAssessmentTypes[mainIdx].mainTypeOptions, "value", "description");
        this.$set(this.formInfo.primaryAssessmentTypes[mainIdx], "skillTypeDescription", skillTypeDescription);
        // 获取子类型列表
        this.getSubTypeList(val, mainIdx);
        // 获取变更前的值
        let prevVal = this.selectedMainTypeIds[mainIdx];
        // 存储已选的主类型id
        this.selectedMainTypeIds[mainIdx] = val;
        // 变更兄弟主类型的mainTypeOptions中的禁用项
        this.formInfo.primaryAssessmentTypes.filter((t, i) => i != mainIdx).map(j => {
            // 移除变更前的禁用项
            if (prevVal) {
                j.mainTypeOptions.find(k => k.value == prevVal).disabled = false;
            }
            // 将新值设为禁用项
            j.mainTypeOptions.find(k => k.value == val).disabled = true;
            return j;
        });
        (this.$refs.mainTypeFormInfo as any).clearValidate(`primaryAssessmentTypes.${mainIdx}.skillTypeId`);
        console.log("执行变更主类型 formInfo.primaryAssessmentTypes", this.formInfo.primaryAssessmentTypes);
    }
    // 删除当前主类型
    public async handleDeleteMainType(mainIdx: number) {
        try {
            await this.$confirm('确定要删除当前主类型吗?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            });
            // 获取当前主类型的值
            let currentVal = this.formInfo.primaryAssessmentTypes[mainIdx].skillTypeId;
            if (currentVal) {
                // 删除已选的主类型id
                this.selectedMainTypeIds.splice(mainIdx, 1);
                // 在兄弟主类型的mainTypeOptions中将该值设为可用项
                this.formInfo.primaryAssessmentTypes.filter((t, i) => i !== mainIdx).map(j => {
                    j.mainTypeOptions.find(k => k.value == currentVal).disabled = false;
                    return j;
                })
            }
            // 前端逻辑删除
            this.$delete(this.formInfo.primaryAssessmentTypes, mainIdx);
            console.log("删除当前主类型, 重组mainTypeOptions", this.formInfo.primaryAssessmentTypes);
            this.$message({
                message: '删除成功',
                type: 'success'
            });
        } catch (e) {
            this.$message({
                type: 'info',
                message: '已取消删除'
            });
        }
    }
    // 校验除表单以外的数据
    public validateData(): boolean {
        console.log("validateData", this.formInfo.primaryAssessmentTypes);
        // TODO 待coding! check该测评单下至少有一条测评项数据
        let flag = true;
        // flag = this.formInfo.primaryAssessmentTypes.some(t => {
        //     return t.subAssessmentTypes.some(j => {
        //         return j.assessmentTypeItems.lenth
        //     })
        // })
        // console.log("flag", flag);
        // if (!flag) {
        //     this.$message.error("至少要添加一条数据！");
        // }
        return flag;
    }
    // 多选
    public handleSelectionChange(mainIdx: number, subIdx: number, val: any[]) {
        this.$set(this.formInfo.primaryAssessmentTypes[mainIdx].subAssessmentTypes[subIdx], "multipleSelectionKey", val.map(t => t.key));
        console.log("multipleSelectionKey", this.formInfo.primaryAssessmentTypes[mainIdx].subAssessmentTypes[subIdx].multipleSelectionKey);
    }
    // 新增/编辑/查看
    public async handManage(type: string, mainIdx: number, subIdx: number, rowIdx: number, row?: any) {
        console.log("新增/编辑/查看", type, mainIdx, subIdx, rowIdx, row);
        this.itemDialogType = type;
        this.currentMainIndex = mainIdx;
        this.currentSubIndex = subIdx;
        this.currentRowIndex = rowIdx;
        if (type === ADD) {
            this.currentRowData = null;
        } else if (type === EDIT) {
            this.currentRowData = deepCopy(row);
        }
        this.itemDialogVisible = true;
    }
    // 删除
    public async handDelete(mainIdx: number, subIdx: number, rowIdx: number, row: any): Promise<void> {
        console.log("row", mainIdx, subIdx, rowIdx, row);
        try {
            await this.$confirm('确定要删除吗?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            });
            // 执行前端逻辑删除
            this.formInfo.primaryAssessmentTypes[mainIdx].subAssessmentTypes[subIdx].assessmentTypeItems.splice(rowIdx, 1);
            this.$message({
                message: '删除成功',
                type: 'success'
            });
        } catch (e) {
            this.$message({
                type: 'info',
                message: '已取消删除'
            });
        }
    }
    // 批量删除
    public async handBatchDelete(mainIdx: number, subIdx: number): Promise<void> {
        try {
            await this.$confirm('确定要删除吗?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            });
            // 执行前端逻辑删除
            let multipleSelectionKey = this.formInfo.primaryAssessmentTypes[mainIdx].subAssessmentTypes[subIdx].multipleSelectionKey;
            let newArr = this.formInfo.primaryAssessmentTypes[mainIdx].subAssessmentTypes[subIdx].assessmentTypeItems.filter(t => (!multipleSelectionKey.includes(t.key)));
            this.$set(this.formInfo.primaryAssessmentTypes[mainIdx].subAssessmentTypes[subIdx], "assessmentTypeItems", newArr);
            this.$set(this.formInfo.primaryAssessmentTypes[mainIdx].subAssessmentTypes[subIdx], "multipleSelectionKey", []);
            this.$message({
                message: '删除成功',
                type: 'success'
            });
        } catch (e) {
            this.$message({
                type: 'info',
                message: '已取消删除'
            });
        }
    }
    // 保存子Dialog
    public itemDialogSubmit(data: any) {
        console.log("保存子table", data, this.currentMainIndex, this.currentSubIndex, this.currentRowIndex);
        let _items = deepCopy(this.formInfo.primaryAssessmentTypes[this.currentMainIndex].subAssessmentTypes[this.currentSubIndex].assessmentTypeItems);
        this.itemDialogVisible = false;
        // 赋值当前table
        if (this.itemDialogType === ADD) {
            _items.push({ ...deepCopy(data), key: new Date().getTime() });
        } else {
            this.$set(_items, this.currentRowIndex, deepCopy(data));
        }
        // 重新排序
        _items = reorderTable(_items, "displayOrder");
        this.$set(this.formInfo.primaryAssessmentTypes[this.currentMainIndex].subAssessmentTypes[this.currentSubIndex], "assessmentTypeItems", _items);
    }
    // 关闭子Dialog
    public itemDialogCancel() {
        this.itemDialogVisible = false;
        this.clearData();
    }
}
