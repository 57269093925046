import { Inject } from '@cds/common';
import { HttpService } from '@/services/common/http.service';

export class LevelInformationService {
  @Inject(HttpService) private http!: HttpService;
  // 添加
  public createLevel(param: Dict<any>): Promise<any> {
    return this.http.post('/wecom-skill-backend/admin/level/create', param);
  }
  // 编辑
  public updateLevel(param: Dict<any>): Promise<any> {
    return this.http.post('/wecom-skill-backend/admin/level/update', param);
  }
  // 查询
  public getLevelList(param: Dict<any>): Promise<any> {
    return this.http.post('/wecom-skill-backend/admin/level/page', param);
  }
  // 详情
  public getDetailById(id: string | number): Promise<any> {
    return this.http.get(`/wecom-skill-backend/admin/level/detail/${id}`);
  }
  // 删除
  public removeLevel(param: Dict<any>): Promise<any> {
    return this.http.post('/wecom-skill-backend/admin/level/remove', param);
  }
  // 子级删除校验
  public itemRemoveCheck(param: Dict<any>): Promise<any> {
    return this.http.post(
      '/wecom-skill-backend/admin/level/item/removeCheck',
      param
    );
  }
}
